/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@keyframes slideIn {
    0% {
        transform: translateX(900px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(900px);
    }
}

.FieldGroup-Wrapper {
    .FieldGroup-Wrapper_address,
    .FieldGroup-Wrapper_nameGroup {
        .FieldGroup {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.MyAccount-Button,
.MyAccount-Button_Cancel {
    @include desktop {
        min-width: 189px;
    }
}

.MyAccount-Button {
    background-color: #739536;
    color: #FFFFFF;
    font-size: 14px;
}

.MyAccount-Button_Cancel {
    border: 1px solid #000000;
    color: #000000;
    font-size: 14px;
    background-color: #ffffff;
}

.MyAccountAddressForm {
    &-Title {
        display: flex;
        grid-column-gap: 70px;
        column-gap: 70px;
        border-block-end: 1px solid #e0e0e0;
        padding-block-end: 20px;
        align-items: center;
        font-weight: 700;
        font-size: 20px;
        justify-content: space-between;

        @include mobile {
            display: none;
        }

        .Button {
            background: transparent;
            border: none;

            &:hover {
                background: transparent;
                border: none;
            }
        }
    }

    &-searchToggle {
        font-size: 14px;
        font-weight: 600;
        margin-block-start: 2rem;
        text-decoration: underline;
        cursor: pointer;
    }
}

.pac-container {
    z-index: 99999;
    border-top: 1px solid transparent;
    box-shadow: none;
}

.GoogleMap {
    &-AddressSugestion {
        padding-block-start: 16px;

        .Field-Label {
            font-size: 14px;
        }

        input {
            height: 48px;
            width: 100%;
            border-radius: 8px;
            border: 1px solid #DFDFDF;
            font-size: 16px;
        }
        
        svg {
            position: absolute;
            inset-inline-end: 15px;
            inset-block-end: 15px;
        }

        &:has(input:not(:placeholder-shown) ) {
            svg {
                display: none;
            }
        }
    }

    .Form {
        padding-block: 0px;
    }
}

.Field-Wrapper.isPreFilled .Field.Field .Field-LabelContainer .Field-Label {
    margin-block-end: 20px;
}

.MyAccountAddressPopup.MyAccountAddressPopup_isVisible {
    .Popup-CloseBtn {
        inset-inline-end: 10px;
        inset-block-start: 26px;
    }

    .Popup-Content {
        animation: slideIn .5s forwards;
    }
}

.MyAccountAddressPopup.MyAccountAddressPopup_isVisible.willClose {
    .Popup-Content {
        animation: slideOut .6s forwards;
    }
}
