/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountTabList {
    @include desktop {
        background-color: var(--my-account-tab-list-background);
        padding: 12px 0;
    }

    @include tablet {
        padding: 0;
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
            padding: 2rem;
        }

        @include tablet {
            padding: 0;

            ul {
                display: flex;
                overflow-x: auto;
            }
        }

        &_isContentExpanded {
            @include mobile {
                padding-block-start: 0;
                padding-block-end: 14px;
                padding-inline: 14px;
            }
        }
    }

    &-desktop {
        @include desktop {
            margin-block-start: 0;
            background-color: var(--my-account-tab-list-background);
            padding: 2rem;
            border: 1px solid rgba(10,9,3,0.16);
        }

        @include tablet {
            padding: 0;
            display: flex;
            overflow-x: auto;
        }
    }

            
            

    &-ExpandableContentButton {
        padding-inline: 16px;

        @include desktop {
            display: none;
        }

        &::before,
        &::after {
            inset-inline-end: 24px;

            @include mobile {
                inset-inline-end: 28px;
            }
        }
    }

    &-Separator {
        display: none;
    }

    &-List-ul {
        padding-inline: 10px;
        margin-block: 10px;
        display: flex;
        overflow: scroll;
        column-gap: 25px;
        background-color: #fff;

        &::-webkit-scrollbar {
            height: 0px; /* for horizontal scrollbars */
        }
    }
}
