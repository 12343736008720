/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.MyAccountAddressBook {
    display: grid;
    grid-template-columns: 1fr;

    .AddressHeading {
        margin: 3rem 0 2rem 0;
        font-size: 1.5rem;
        font-weight: 600;
    }

    .OtherAddress {
        padding: 2rem 0;
    }

    &-Address_Empty {
        text-align: center;
        margin-block-start: 80px;

        img {
            width: 65px;
            height: 102px;
        }

        h2 {
            font-size: 20px;
            margin-block-end: 10px;
        }

        p {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            margin-block-end: 3rem;
        }
    }

    .DefaultAddress {
        border-bottom: 1px solid #e0e0e0;
        padding-block-end: 30px;
    }

    .Address-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;

        .Address-Action {
            .Action-Buttons {
                @include mobile {
                    width: 100%;
                }
            }
        }

        @include mobile {
            grid-template-columns: repeat(1, 1fr);
        }

        .ShippingAddress,
        .BillingAddress {
            .MyAccountAddressTable {
                .AddressContainer-Active {
                    background: #f2faf7;
                    border: 1px solid #fff;
                }

                .AddressContainer {
                    border: solid 1px #e0e0e0;
                }

                .AddressContainer,
                .AddressContainer-Active {
                    padding: 20px;

                    .AddressContent {
                        display: flex;

                        div {
                            input {
                                height: 17px;
                                margin-block-start: 6px;
                            }

                            input[type="radio"] {
                                appearance: auto;
                                accent-color: #000;
                            }
                        }
                    }

                    .KeyValueTable-Wrapper {
                        .KeyValueTable {
                            tr {
                                border: none;
                                font-size: 14px;
                                font-weight: 600;

                                td {
                                    padding: 5px;
                                }
                            }
                        }
                    }

                    .Button_isHollow {
                        color: #565959;
                        font-size: 14px;

                        @include mobile {
                            width: 40%;
                        }
                    }
                }
            }
        }
    }

    &-Button_EmptyAddress {
        color: #ffffff;
        background: #739536;
        border: 1px solid #739536;
        width: 206px;
        height: 50px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &-action-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-block-end: 14px;
        border-bottom: 1px solid #d8d8d8;
        padding: 24px;
        background: #fff;
        margin: 24px 0;
        flex-wrap: wrap;

        h3 {
            font-size: 20px;

            @include desktop {
                margin-block-start: 0px;
            }

            @include mobile {
                font-size: 16px;
                width: 80%;
            }
        }

        Button {
            height: 46px;
            padding-inline: 40px;
            text-transform: initial;


            @include mobile {
                width: 60%;
                font-size: 12px;
                white-space: nowrap;
                gap: 10px;
                padding: 0;
            }

            @include tablet {
                padding-inline: 10px;
                font-size: 12px;
            }

            svg {
                fill: var(--primary-base-color);
                width: 15px;
                height: 15px;

                @include tablet {
                    width: 16px;
                    height: 15px;
                    margin-inline-end: 10px;
                }

                margin-inline-end: 16px;
                border: 1px solid var(--primary-base-color);
                border-radius: 20px;

                @include mobile {
                    width: 12px;
                    height: 12px;
                    margin-inline-end: 0;
                }
            }
            
            &:not([disabled]):hover {
                background: var(--primary-base-color);
                color: #fff;
                height: 46px;
                padding-inline: 40px;

                svg {
                    fill: #fff;
                    border: 1px solid #fff;
                }
            }
        }
    }

    &-Button_NewAddress {
        // color: #000;
        // border-color: #000000;
        // max-width: 214px;

        @include  mobile {
            max-width: none;
            padding-inline: 10px;
            font-size: 12px;
        }

        button {
            margin-inline-start: 12px;

            @include mobile {
                margin-block-start: 14px;
                margin-inline-start: 0;
                font-size: 12px;
            }
        }
    }

    .Default-Address {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 24px;

        @include mobile {
            display: block;
        }

        .MyAccountAddressDashboard {
            background: #fff;
            margin-block: 16px;
            padding: 24px;
            box-shadow: 1px 1px 13px #00000005;

            @include mobile {
                padding: 16px 12px;
                margin-block: 16px;
            }
        }
    }

    .tab-heading {
        border-bottom: 1px solid var(--primary-base-color);
        display: flex;
        justify-content: space-between;
        font-size: 20px;
        padding-block-end: 6px;
        width: 100%;
        font-weight: 600;
        padding: 16px 0;

        @include mobile {
            font-size: 14px;
        }
    }
}
